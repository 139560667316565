import clsx from 'clsx';
import React from 'react';

import { ButtonsContainer } from '@/components/ButtonsContainer';
import { RenderCarousel } from '@/components/RenderCarousel';
import { RenderExternalNews } from '@/components/RenderExternalNews';
import { RenderImage } from '@/components/RenderImage';
import { RenderMatchCardV3 } from '@/components/RenderMatchCardV3';
import { RenderMatches } from '@/components/RenderMatches';
import { RenderNews } from '@/components/RenderNews';
import { RenderResultCard } from '@/components/RenderResultCard';
import { RenderVideo } from '@/components/RenderVideo';
import { TourTitle } from '@/components/TourTitle';
import { type BlockMetadataInterace, BlockWidgetTypes } from '@/utils/types';

const RenderBlock = ({ blockFooter, blockComponents, blockHeader }: BlockMetadataInterace) => {
	const blockContainerClasses = clsx('flex flex-col overflow-hidden rounded-lg bg-white shadow-sm', {
		'gap-4 sm:gap-6': blockFooter || blockHeader,
		'pb-4 sm:pb-6': blockFooter,
		'pt-4 sm:pt-6': blockFooter && !blockHeader && blockComponents.length === 0
	});
	const blockComponentsContainerClasses = clsx('flex flex-col', {
		'gap-4 sm:gap-6': blockComponents.length > 1,
		'py-4 sm:py-6 px-4 sm:px-6': blockComponents.length > 0 && !blockFooter && !blockHeader,
		'px-4 sm:px-6': blockFooter || blockHeader,
		'pb-4 sm:pb-6': blockHeader && !blockFooter,
		'pt-4 sm:pt-6': !blockHeader && blockFooter
	});

	return (
		<div className={blockContainerClasses}>
			{blockHeader && (
				<TourTitle
					title={blockHeader.title}
					altTitle={blockHeader.altTitle}
					imagePosition={
						// eslint-disable-next-line no-nested-ternary
						blockHeader.headerType === 'type-a' ? 'left' : blockHeader.headerType === 'type-b' ? 'right' : 'no-image'
					}
					image={blockHeader.imageURL}
					imageAltText={blockHeader.imageAltText}
					backgroundColor={blockHeader.backgroundColor}
					link={blockHeader.link}
				/>
			)}
			{blockComponents.length > 0 && (
				<div className={blockComponentsContainerClasses}>
					{blockComponents.map((blockComponent) => {
						return (
							<React.Fragment key={blockComponent.id}>
								{blockComponent.type === BlockWidgetTypes.NEWS && blockComponent.prefetchedData ? (
									<RenderNews newsType={blockComponent.newsType} {...blockComponent.prefetchedData} />
								) : blockComponent.type === BlockWidgetTypes.VIDEO ? (
									<RenderVideo {...blockComponent} />
								) : blockComponent.type === BlockWidgetTypes.IMAGE ? (
									<RenderImage {...blockComponent} />
								) : blockComponent.type === BlockWidgetTypes.MATCH && blockComponent.prefetchedData ? (
									<RenderMatches title={blockComponent.title} matches={blockComponent.prefetchedData} />
								) : blockComponent.type === BlockWidgetTypes.RESULT && blockComponent.prefetchedData ? (
									<RenderResultCard matches={blockComponent.prefetchedData} />
								) : blockComponent.type === BlockWidgetTypes.EXTERNAL_NEWS ? (
									<RenderExternalNews
										url={blockComponent.url}
										newsType={blockComponent.newsType}
										{...(blockComponent.prefetchedData ? blockComponent.prefetchedData : blockComponent)}
									/>
								) : blockComponent.type === BlockWidgetTypes.CAROUSEL && blockComponent.prefetchedData ? (
									<RenderCarousel carouselNews={blockComponent.prefetchedData} />
								) : blockComponent.type === BlockWidgetTypes.MATCHV3 && blockComponent.prefetchedData ? (
									<RenderMatchCardV3 matches={blockComponent.prefetchedData} />
								) : null}
							</React.Fragment>
						);
					})}
				</div>
			)}
			{blockFooter && <ButtonsContainer buttons={blockFooter.buttons} />}
		</div>
	);
};

export default RenderBlock;
