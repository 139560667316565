import { faClock } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import moment from 'moment';
import React from 'react';

import getCDNURL from '@/utils/getCDNURL';

import styles from './NewsCard.module.css';

export interface NewsCardProps {
	description?: string;
	externalNews?: boolean;
	image?: string;
	imageAltText?: string;
	publishDateDisplayed?: string | Date;
	title: string;
	author?: string;
	url?: string;
}

const NewsCard = ({ description, image, imageAltText, publishDateDisplayed, title, author, url }: NewsCardProps) => {
	const wrapperClasses = clsx(styles.wrapper);
	const imageClasses = clsx(styles.image);
	const contentClasses = clsx(styles.content);
	const titleClasses = clsx(styles.title);
	const descriptionClasses = clsx(styles.description);
	const iconTimeClasses = clsx(styles.icon__time);
	const iconClasses = clsx(styles.icon);
	const timeClasses = clsx(styles.time);

	const time = publishDateDisplayed ? moment.utc(publishDateDisplayed).fromNow() : '';

	return (
		<div className={wrapperClasses} itemScope itemType="https://schema.org/NewsArticle">
			{url && <meta itemProp="url" content={url} />}
			{publishDateDisplayed && <meta itemProp="datePublished" content={moment(publishDateDisplayed).format('YYYY–MM-DD HH:mm:ss')} />}
			<img
				className={imageClasses}
				style={{ objectFit: 'cover' }}
				alt={imageAltText || title}
				src={image || `${getCDNURL(`${process.env.NEXT_PUBLIC_IMAGE_CDN}/pickleball-app/assets/images/placeholder.png`, 640, 640)}`}
				itemProp="thumbnail"
			/>
			{title && (
				<div className={contentClasses}>
					<h3 className={titleClasses} itemProp="headline">
						{title}
					</h3>
					{description && (
						<p className={descriptionClasses} itemProp="accessibilitySummary">
							{description}
						</p>
					)}
					{time && (
						<div className={iconTimeClasses}>
							<FontAwesomeIcon icon={faClock} size="sm" className={iconClasses} />
							<p className={timeClasses}>{time}</p>
							{author && (
								<>
									<span>{` - `}</span>
									<p className={timeClasses} itemScope itemProp="author" itemType="https://schema.org/Person">
										<span itemProp="name">{author}</span>
									</p>
								</>
							)}
						</div>
					)}
				</div>
			)}
		</div>
	);
};

export default NewsCard;
