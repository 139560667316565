import { ResultCard } from '@pickleballinc/react-ui';
import Link from 'next/link';
import React from 'react';

import { MatchExtended, MatchInfo } from '@/modules/ticker/types';
import { formatMatchInfo } from '@/utils/formatMatchInfo';

export interface RenderMatchesProps {
	matches: MatchInfo[];
}

const RenderResultCard = ({ matches, rounded }: RenderMatchesProps & { rounded?: boolean }) => {
	if (matches.length === 0) return null;

	const duoMatch = matches && matches[0] && matches[0].teamOnePlayerTwoUuid;

	const formattedResults: MatchExtended[] = formatMatchInfo({
		matchInfos: matches,
		imagesWidth: duoMatch ? 22 : 38,
		imagesHeight: duoMatch ? 22 : 38,
		matchCardType: 'RESULT'
	});

	return (
		<div className="-mx-4 flex w-[calc(100%+32px)] flex-col gap-4 rounded-lg sm:-mx-6 sm:w-[640px]">
			{formattedResults.map((result) => (
				<Link key={result.id} className="cursor-pointer hover:opacity-80" href={`/results/match/${result.id}`}>
					<ResultCard match={result} rounded={rounded} />
				</Link>
			))}
		</div>
	);
};

export default RenderResultCard;
