import Link from 'next/link';
import React from 'react';

import { RenderNewsInterface } from '@/utils/actions/homepage/getNews';
import { NewsFormatType } from '@/utils/types';

import { NewsCard } from '../NewsCard';
import { SmallNewsCard } from '../SmallNewsCard';

export interface RenderNewsProps extends RenderNewsInterface {
	newsType: NewsFormatType;
}

const RenderNews = ({
	authorFullName,
	description,
	externalUrl,
	imageAltText,
	imageUrl,
	newsType,
	publishDateDisplayed,
	slug,
	smallNewsCardImgUrl,
	title,
	categorySlug
}: RenderNewsProps) => {
	const newsURL = `https://${process.env.NEXT_PUBLIC_REWRITE_DOMAIN_NAME}/${categorySlug}/${slug}`;
	return (
		<Link className="hover:opacity-80" href={externalUrl || `/${categorySlug}/${slug}`} target={externalUrl ? '_blank' : '_self'}>
			{newsType === 'format-a' ? (
				<NewsCard
					url={newsURL}
					image={imageUrl}
					imageAltText={imageAltText}
					title={title}
					description={description}
					publishDateDisplayed={publishDateDisplayed}
					author={authorFullName}
				/>
			) : (
				<SmallNewsCard
					url={newsURL}
					image={smallNewsCardImgUrl || imageUrl}
					imageAltText={imageAltText}
					title={title}
					description={description}
					publishDateDisplayed={publishDateDisplayed}
					author={authorFullName}
				/>
			)}
		</Link>
	);
};

export default RenderNews;
