import Link from 'next/link';
import React from 'react';

import { RenderSideNewsInterface } from '@/utils/actions/homepage/getNewsByUuids';

import { ExtraSmallNewsCard } from '../ExtraSmallNewsCard';

export interface RenderSideNewsProps {
	title: string;
	news: RenderSideNewsInterface[];
}

const RenderSideNews = ({ title, news }: RenderSideNewsProps) => {
	if (news.length === 0) return null;

	return (
		<div className="flex w-full flex-col rounded-lg bg-white shadow-sm">
			<h2 className="flex h-[52px] w-full items-center border-b border-solid border-gray-200 ps-4 text-left text-sm font-bold leading-5 tracking-normal">
				{title}
			</h2>
			<div className="flex w-full flex-col gap-4 px-3 py-4">
				{news.map((newsObj, index) => {
					const newsURL = `https://${process.env.NEXT_PUBLIC_REWRITE_DOMAIN_NAME}/${newsObj.categorySlug}/${newsObj.slug}`;
					return (
						<React.Fragment key={newsObj.slug}>
							<Link className="hover:opacity-80" href={`/${newsObj.categorySlug}/${newsObj.slug}`}>
								<ExtraSmallNewsCard
									url={newsURL}
									description={newsObj.description}
									image={newsObj.imageUrl}
									imageAltText={newsObj.imageAltText}
									title={newsObj.title}
									author={newsObj.authorFullName}
									publishDateDisplayed={newsObj.publishDateDisplayed}
								/>
							</Link>
							{index !== news.length - 1 && (
								<div className="flex justify-center">
									<div className="flex w-[274px] content-center items-center justify-center self-center border-b border-solid border-gray-200" />
								</div>
							)}
						</React.Fragment>
					);
				})}
			</div>
		</div>
	);
};

export default RenderSideNews;
