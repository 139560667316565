/* eslint-disable no-nested-ternary */
import { faClock } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import moment from 'moment';
import React from 'react';

import styles from '@/components/CarouselNewsCard/CarouselNewsCard.module.css';
import getCDNURL from '@/utils/getCDNURL';

export interface CarouselNewsCardProps {
	image?: string;
	imageAltText?: string;
	publishDateDisplayed?: string | Date;
	title: string;
	author?: string;
	url?: string;
}

const CarouselNewsCard = ({ image, imageAltText, publishDateDisplayed, title, author, url }: CarouselNewsCardProps) => {
	const wrapperClasses = clsx(styles.wrapper);
	const imageClasses = clsx(styles.image, {
		[styles['image--no-src'] as string]: !image
	});
	const contentClasses = clsx(styles.content);
	const titleClasses = clsx(styles.title);
	const iconTimeClasses = clsx(styles.icon__time);
	const iconClasses = clsx(styles.icon);
	const timeClasses = clsx(styles.time);
	const authorClasses = clsx(styles.author);

	const time = publishDateDisplayed ? moment.utc(publishDateDisplayed).fromNow() : '';

	return (
		<div className={wrapperClasses} itemScope itemType="https://schema.org/ListItem">
			<div className={wrapperClasses} itemScope itemType="https://schema.org/NewsArticle">
				{url && <meta itemProp="url" content={url} />}
				{publishDateDisplayed && <meta itemProp="datePublished" content={moment(publishDateDisplayed).format('YYYY–MM-DD HH:mm:ss')} />}
				<img
					alt={imageAltText || title}
					src={image || `${getCDNURL(`${process.env.NEXT_PUBLIC_IMAGE_CDN}/pickleball-app/assets/images/placeholder.png`, 606, 606)}`}
					className={imageClasses}
					itemProp="thumbnail"
				/>
				<div className={contentClasses}>
					<h3 className={titleClasses} itemProp="headline">
						{title}
					</h3>
					{time && (
						<div className={iconTimeClasses}>
							<FontAwesomeIcon icon={faClock} size="sm" className={iconClasses} />
							<p className={timeClasses}>{time}</p>
							{author && (
								<>
									<span>{` - `}</span>
									<p className={authorClasses} itemScope itemProp="author" itemType="https://schema.org/Person">
										<span itemProp="name">{author}</span>
									</p>
								</>
							)}
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

export default CarouselNewsCard;
