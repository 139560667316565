'use client';

import { Button } from '@pickleballinc/react-ui';
import React from 'react';

export interface LoadMoreButtonProps {
	currentPage: number;
	setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
}

const LoadMoreButton = ({ currentPage, setCurrentPage }: LoadMoreButtonProps) => {
	return (
		<Button
			className="w-64 justify-center bg-white !text-sm !font-semibold !not-italic !leading-5 text-experiment"
			onClick={() => setCurrentPage(currentPage + 1)}
			variant="secondary"
		>
			Load more
		</Button>
	);
};

export default LoadMoreButton;
