const NEW_NEXT_PUBLIC_IMAGE_CDN = 'https://pickleball-pbc.b-cdn.net/';
const NEXT_PUBLIC_IMAGE_CDN = 'https://cdn11.bigcommerce.com';

export default function getPickleballCentralCDNURL(url: string, width: number, height: number): string {
	if (NEXT_PUBLIC_IMAGE_CDN && url.startsWith(NEXT_PUBLIC_IMAGE_CDN)) {
		const basePath = url.replace(NEXT_PUBLIC_IMAGE_CDN, NEW_NEXT_PUBLIC_IMAGE_CDN);
		const sizeQuery = width && height ? `?width=${width}&height=${height}` : '';

		return `${basePath}${sizeQuery}`;
	}

	return url;
}
