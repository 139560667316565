/* eslint-disable @next/next/no-img-element */
import clsx from 'clsx';
import Link from 'next/link';
import React from 'react';

import getCDNURL from '@/utils/getCDNURL';
import { WebsiteInterface } from '@/utils/types';

import styles from './CompanyWebsites.module.css';

export interface CompanyWebsitesProps {
	title?: string;
	data: WebsiteInterface[];
}

const CompanyWebsites = ({ data, title }: CompanyWebsitesProps) => {
	const wrapperClasses = clsx(styles.wrapper);
	const headerClasses = clsx(styles.header);
	const linkClasses = clsx(styles.link);
	const imageContainerClasses = clsx(styles.image__container);
	const imageWrapperClasses = clsx(styles.image__wrapper);
	const imageClasses = clsx(styles.image);
	const nameClasses = clsx(styles.name);
	const borderContainerClasses = clsx(styles.border__container);
	const borderClasses = clsx(styles.border);

	return (
		<div className={wrapperClasses}>
			<h2 className={headerClasses}>{title || 'Our Sites'}</h2>
			<ul>
				{data.map((dataObject, index) => {
					return (
						<li key={dataObject.name}>
							<Link className={linkClasses} href={dataObject.link} rel="noopener noreferrer" target="_blank">
								<div className={imageContainerClasses}>
									<div className={imageWrapperClasses}>
										<img className={imageClasses} src={getCDNURL(dataObject.image, 20, 20)} alt="" />
									</div>
									<div className={nameClasses}>{dataObject.name}</div>
								</div>
							</Link>
							{index !== data.length - 1 && (
								<div className={borderContainerClasses}>
									<div className={borderClasses} />
								</div>
							)}
						</li>
					);
				})}
			</ul>
		</div>
	);
};

export default CompanyWebsites;
