import Link from 'next/link';
import React from 'react';

import { HeadlineNewsInterface } from '@/utils/types';

export interface RenderHeadlinesProps {
	headlines: HeadlineNewsInterface[];
}

const RenderHeadlines = ({ headlines }: RenderHeadlinesProps) => {
	if (headlines.length === 0) return null;

	return (
		<div className="flex w-full flex-col items-center justify-center rounded-xl bg-white shadow-sm">
			<h2 className="flex h-[52px] w-full items-center border-b border-solid border-gray-200 ps-4 text-left text-sm font-bold leading-5 tracking-normal">
				Headlines
			</h2>
			<ul>
				{headlines.map((news, index) => (
					<li key={news.slug}>
						<Link href={`/${news.categorySlug || 'news'}/${news.slug}`} rel="noopener noreferrer" target="_self" className="w-full">
							<div className="flex min-h-[48px] w-full cursor-pointer px-4 py-[5px]">
								<h3 className="flex w-full items-center text-left text-xs font-normal leading-[18px] tracking-normal text-gray-500 hover:text-opacity-80">
									{news.headlineForHomepage || news.title}
								</h3>
							</div>
							{headlines.length - 1 !== index && (
								<div className="flex w-full justify-center px-4">
									<div className="flex w-full content-center items-center justify-center self-center border-b border-solid border-gray-200" />
								</div>
							)}
						</Link>
					</li>
				))}
			</ul>
		</div>
	);
};

export default RenderHeadlines;
