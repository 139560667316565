import { Swiper } from '@pickleballinc/react-ui';
import SwiperItem from '@pickleballinc/react-ui/Swiper/SwiperItem';
import { useRouter } from 'next/navigation';
import React from 'react';

import CarouselNewsCard from '@/components/CarouselNewsCard/CarouselNewsCard';
import { RenderSideNewsInterface } from '@/utils/actions/homepage/getNewsByUuids';

export interface RenderCarouselProps {
	carouselNews: RenderSideNewsInterface[];
}

const RenderCarousel = ({ carouselNews }: RenderCarouselProps) => {
	const router = useRouter();

	if (carouselNews.length === 0) return null;

	return (
		<div className="mx-1 rounded-lg bg-white py-2 shadow-sm">
			<div className="sm:hidden">
				<Swiper
					itemScope
					itemType="https://schema.org/ItemList"
					numberOfItemsPerSwipe={1}
					items={carouselNews.map((dataObj) => {
						const newsURL = `https://${process.env.NEXT_PUBLIC_REWRITE_DOMAIN_NAME}/${dataObj.categorySlug}/${dataObj.slug}`;
						return {
							children: (
								<SwiperItem key={dataObj.slug} onClick={() => router.push(newsURL)}>
									<CarouselNewsCard
										url={newsURL}
										author={dataObj.authorFullName}
										image={dataObj.imageUrl}
										imageAltText={dataObj.imageAltText}
										title={dataObj.title}
										publishDateDisplayed={dataObj.publishDateDisplayed}
									/>
								</SwiperItem>
							)
						};
					})}
				/>
			</div>
			<div className="hidden sm:block">
				<Swiper
					itemScope
					itemType="https://schema.org/ItemList"
					numberOfItemsPerSwipe={carouselNews.length < 3 ? carouselNews.length : 3}
					items={carouselNews.map((dataObj) => {
						const newsURL = `https://${process.env.NEXT_PUBLIC_REWRITE_DOMAIN_NAME}/${dataObj.categorySlug}/${dataObj.slug}`;
						return {
							children: (
								<SwiperItem
									key={dataObj.slug}
									onClick={() => router.push(newsURL)}
									className="hover:!cursor-pointer hover:rounded-lg hover:opacity-70"
								>
									<CarouselNewsCard
										url={newsURL}
										image={dataObj.imageUrl}
										imageAltText={dataObj.imageAltText}
										title={dataObj.title}
										publishDateDisplayed={dataObj.publishDateDisplayed}
									/>
								</SwiperItem>
							)
						};
					})}
				/>
			</div>
		</div>
	);
};

export default RenderCarousel;
