import React from 'react';

import { ColumnInterfaceExtended } from '@/utils/actions/homepage/getHomepageStructure';
import { ColumnWidgetTypes } from '@/utils/types';

import Advertisement from '../RenderAd/Advertisement';
import RenderFloatingAd from '../RenderAd/RenderFloatingAd';
import { RenderCompanyWebsites } from '../RenderCompanyWebsites';
import { RenderFeaturedPartners } from '../RenderFeaturedPartners';
import { RenderHeadlines } from '../RenderHeadlines';
import { RenderRankings } from '../RenderRankings';
import { RenderSideNews } from '../RenderSideNews';
import { RenderSocialMedia } from '../RenderSocialMedia';

const RenderSidebar = ({ widgets, ipAddress }: ColumnInterfaceExtended) => {
	return (
		<div className="hidden w-[300px] shrink-0 flex-col gap-4 py-6 lg:flex">
			{widgets.map((widget, index) => (
				<React.Fragment key={widget.uuid}>
					{widget.type === ColumnWidgetTypes.SIDE_NEWS && widget.metadata.prefetchedData ? (
						<RenderSideNews title={widget.metadata.title} news={widget.metadata.prefetchedData} />
					) : widget.type === ColumnWidgetTypes.SOCIAL_MEDIA ? (
						<RenderSocialMedia {...widget.metadata} />
					) : widget.type === ColumnWidgetTypes.COMPANY_WEBSITES ? (
						<RenderCompanyWebsites {...widget.metadata} />
					) : widget.type === ColumnWidgetTypes.FEATURED_PARTNERS ? (
						<RenderFeaturedPartners {...widget.metadata} />
					) : widget.type === ColumnWidgetTypes.RANKINGS && widget.metadata.prefetchedData ? (
						<RenderRankings metadata={widget.metadata.data} rankings={widget.metadata.prefetchedData} />
					) : widget.type === ColumnWidgetTypes.HEADLINES && widget.metadata.prefetchedData ? (
						<RenderHeadlines headlines={widget.metadata.prefetchedData} />
					) : widget.type === ColumnWidgetTypes.SIDE_ADVERTISEMENT ? (
						<>
							{/*Last advertisement in the sidebar column should be floating when scrolling*/}
							{index === widgets.length - 1 ? (
								<RenderFloatingAd
									height={+widget.metadata.height}
									image={widget.metadata.prefetchedData}
									ipAddress={ipAddress}
									pageId={100000}
									place={widget.metadata.place ? +widget.metadata.place : 0}
									width={+widget.metadata.width}
									zoneId={widget.metadata.zoneId}
									isFetchedSs
								/>
							) : (
								<Advertisement
									height={+widget.metadata.height}
									image={widget.metadata.prefetchedData}
									ipAddress={ipAddress}
									pageId={100000}
									place={widget.metadata.place ? +widget.metadata.place : 0}
									width={+widget.metadata.width}
									zoneId={widget.metadata.zoneId}
								/>
							)}
						</>
					) : null}
				</React.Fragment>
			))}
		</div>
	);
};

export default RenderSidebar;
