export const LEADERBOARD_TAB_OPTIONS = [
	{ value: 4, label: 'WD', gender: 'F' },
	{ value: 1, label: 'WS', gender: 'F' },
	{ value: 3, label: 'WX', gender: 'F' },
	{ value: 3, label: 'MX', gender: 'M' },
	{ value: 5, label: 'MD', gender: 'M' },
	{ value: 2, label: 'MS', gender: 'M' }
];

export const LEADERBOARD_DEFAULT_TAB_VALUE = { value: 5, label: 'MD', gender: 'M' };
