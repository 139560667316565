import moment from 'moment';

import type { MatchExtended, MatchInfo } from '@/modules/ticker/types';
import getCDNURL from '@/utils/getCDNURL';

// Featured court titles are "cc", "gs", "gs1", "gs2", "s", "s1", "s2" etc.
const checkIfFeaturedCourt = (courtTitle?: string) => {
	if (!courtTitle) {
		return false;
	}
	const courtTitleInLowerCaseTrimmed = courtTitle.toLowerCase().trim();
	return (
		courtTitleInLowerCaseTrimmed === 'cc' ||
		courtTitleInLowerCaseTrimmed === 'incc' ||
		courtTitleInLowerCaseTrimmed === 'gs' ||
		(courtTitleInLowerCaseTrimmed.startsWith('gs') && /^\d/.test(courtTitleInLowerCaseTrimmed.charAt(2))) ||
		courtTitleInLowerCaseTrimmed === 's' ||
		(courtTitleInLowerCaseTrimmed.startsWith('s') && /^\d/.test(courtTitleInLowerCaseTrimmed.charAt(1)))
	);
};

const getMatchCourtLabel = (courtTitle?: string) => {
	if (!courtTitle) {
		return '';
	}
	const courtTitleInLowerCaseTrimmed = courtTitle.toLowerCase().trim();
	return courtTitleInLowerCaseTrimmed === 'incc' ? 'CC' : courtTitle;
};

const getFormattedRoundText = (roundText?: string) => {
	if (!roundText) {
		return '';
	}

	if (roundText.startsWith('Round')) {
		const roundTextSplit = roundText.split(' ');
		let formattedRoundText = '';

		if (roundTextSplit[1] && roundTextSplit[1] === 'of') {
			return roundText;
		}

		if (roundTextSplit[0]) {
			formattedRoundText += roundTextSplit[0];
		}

		if (roundTextSplit[1]) {
			formattedRoundText += ' of ';
			formattedRoundText += roundTextSplit[1];
		}

		return formattedRoundText;
	}

	return roundText;
};

type MatchCardType = 'TICKER' | 'MATCH' | 'RESULT' | 'MATCHV3';

export interface FormatMatchInfoProps {
	matchInfos: MatchInfo[];
	imagesWidth: number;
	imagesHeight: number;
	matchCardType?: MatchCardType;
}

export function formatMatchInfo({ matchInfos, imagesWidth, imagesHeight, matchCardType }: FormatMatchInfoProps) {
	if (matchInfos.length === 0) return [];

	let filteredMatchInfos = [...matchInfos];
	if (matchCardType === 'TICKER') {
		filteredMatchInfos = matchInfos.filter((match) => {
			const tournamentLocalDate = moment().utc().add(Number(match.utcOffset), 'seconds');
			return (
				(match.localDateMatchCompleted && tournamentLocalDate.isSame(moment(match.localDateMatchCompleted).utc(), 'day')) ||
				(match.localDateMatchAssignedToCourt && tournamentLocalDate.isSame(moment(match.localDateMatchAssignedToCourt).utc(), 'day')) ||
				(match.localDateMatchStart && tournamentLocalDate.isSame(moment(match.localDateMatchStart).utc(), 'day')) ||
				(match.localDateMatchPlannedStart && tournamentLocalDate.isSame(moment(match.localDateMatchPlannedStart).utc(), 'day'))
			);
		});
	}

	const matches: MatchExtended[] = filteredMatchInfos.map((matchInfo) => {
		const teamOneScores = [matchInfo.teamOneGameOneScore];
		const teamTwoScores = [matchInfo.teamTwoGameOneScore];
		const gamesStatus: number[] = [+matchInfo.gameOneStatus];

		const teamOnePlayers = [
			{
				playerId: matchInfo.teamOnePlayerOneUuid,
				firstName: matchInfo.teamOnePlayerOneFirstName,
				lastName: matchInfo.teamOnePlayerOneLastName,
				suffixName: matchInfo.teamOnePlayerOneNameSuffix,
				image:
					matchCardType === 'MATCHV3'
						? matchInfo.teamOnePlayerOnePicture
						: getCDNURL(matchInfo.teamOnePlayerOnePicture, imagesWidth, imagesHeight)
			}
		];
		const teamTwoPlayers = [
			{
				playerId: matchInfo.teamTwoPlayerOneUuid,
				firstName: matchInfo.teamTwoPlayerOneFirstName,
				lastName: matchInfo.teamTwoPlayerOneLastName,
				suffixName: matchInfo.teamTwoPlayerOneNameSuffix,
				image:
					matchCardType === 'MATCHV3'
						? matchInfo.teamTwoPlayerOnePicture
						: getCDNURL(matchInfo.teamTwoPlayerOnePicture, imagesWidth, imagesHeight)
			}
		];

		if (matchInfo.teamOnePlayerTwoUuid) {
			teamOnePlayers.push({
				playerId: matchInfo.teamOnePlayerTwoUuid,
				firstName: matchInfo.teamOnePlayerTwoFirstName,
				lastName: matchInfo.teamOnePlayerTwoLastName,
				suffixName: matchInfo.teamOnePlayerTwoNameSuffix,
				image:
					matchCardType === 'MATCHV3'
						? matchInfo.teamOnePlayerTwoPicture
						: getCDNURL(matchInfo.teamOnePlayerTwoPicture, imagesWidth, imagesHeight)
			});
		}

		if (matchInfo.teamTwoPlayerTwoUuid) {
			teamTwoPlayers.push({
				playerId: matchInfo.teamTwoPlayerTwoUuid,
				firstName: matchInfo.teamTwoPlayerTwoFirstName,
				lastName: matchInfo.teamTwoPlayerTwoLastName,
				suffixName: matchInfo.teamTwoPlayerTwoNameSuffix,
				image:
					matchCardType === 'MATCHV3'
						? matchInfo.teamTwoPlayerTwoPicture
						: getCDNURL(matchInfo.teamTwoPlayerTwoPicture, imagesWidth, imagesHeight)
			});
		}

		if (matchInfo.scoreFormatGameBestOutOf > 1) {
			teamOneScores.push(matchInfo.teamOneGameTwoScore);
			teamTwoScores.push(matchInfo.teamTwoGameTwoScore);
			gamesStatus.push(+matchInfo.gameTwoStatus);
		}

		if (matchInfo.scoreFormatGameBestOutOf > 2) {
			teamOneScores.push(matchInfo.teamOneGameThreeScore);
			teamTwoScores.push(matchInfo.teamTwoGameThreeScore);
			gamesStatus.push(+matchInfo.gameThreeStatus);
		}

		if (matchInfo.scoreFormatGameBestOutOf > 3) {
			teamOneScores.push(matchInfo.teamOneGameFourScore);
			teamTwoScores.push(matchInfo.teamTwoGameFourScore);
			gamesStatus.push(+matchInfo.gameFourStatus);
		}

		if (matchInfo.scoreFormatGameBestOutOf > 4) {
			teamOneScores.push(matchInfo.teamOneGameFiveScore);
			teamTwoScores.push(matchInfo.teamTwoGameFiveScore);
			gamesStatus.push(+matchInfo.gameFiveStatus);
		}

		const formattedRoundText = getFormattedRoundText(matchInfo.roundText);

		let matchObject: MatchExtended = {
			id: matchInfo.matchUuid,
			timezoneAbbreviation: matchInfo.timezoneAbbreviation,
			matchStatus: matchInfo.matchStatus,
			matchCompletedType: matchInfo.matchCompletedType,
			currentServingNumber: matchInfo.currentServingNumber,
			server: matchInfo.server,
			serverFromTeam: matchInfo.serverFromTeam,
			team1: {
				players: [...teamOnePlayers],
				//percentage: Math.round(matchInfo.teamOneWinningPercentage * 10) / 10,
				scores: [...teamOneScores],
				isWinner: matchInfo.matchStatus === 4 && matchInfo.winner === 1
			},
			team2: {
				players: [...teamTwoPlayers],
				//percentage: Math.round((100 - matchInfo.teamOneWinningPercentage) * 10) / 10,
				scores: [...teamTwoScores],
				isWinner: matchInfo.matchStatus === 4 && matchInfo.winner === 2
			},
			gamesStatus: gamesStatus,
			roundNumber: formattedRoundText || (matchInfo.roundNumber ? `Round ${matchInfo.roundNumber}` : '')
		};

		if (matchCardType === 'TICKER') {
			matchObject = {
				...matchObject,
				courtName: checkIfFeaturedCourt(matchInfo.courtTitle) ? getMatchCourtLabel(matchInfo.courtTitle) : '',
				tournamentTitle: matchInfo.tournamentTitle,
				matchTimeStart: moment
					.utc(matchInfo?.localDateMatchCompleted || matchInfo?.localDateMatchStart || matchInfo?.localDateMatchPlannedStart)
					.format('h:mm A')
			};
		} else if (matchCardType === 'MATCHV3') {
			matchObject = {
				...matchObject,
				tournamentTitle: matchInfo.tournamentTitle,
				eventTitle: matchInfo.eventTitle,
				courtName: matchInfo.courtTitle ? `Court ${getMatchCourtLabel(matchInfo.courtTitle)}` : '',
				matchTime: moment
					.utc(matchInfo?.localDateMatchCompleted || matchInfo?.localDateMatchStart || matchInfo?.localDateMatchPlannedStart)
					.format('MMMM DD, YYYY'),
				matchCardImageUrl: matchInfo.matchCardImageUrl
			};
		} else {
			matchObject = {
				...matchObject,
				eventTitle: matchInfo.eventTitle,
				courtName: matchInfo.courtTitle ? `Court ${getMatchCourtLabel(matchInfo.courtTitle)}` : '',
				matchTime: moment
					.utc(matchInfo?.localDateMatchCompleted || matchInfo?.localDateMatchStart || matchInfo?.localDateMatchPlannedStart)
					.format('h:mm A'),
				matchTimeStart: moment
					.utc(matchInfo?.localDateMatchCompleted || matchInfo?.localDateMatchStart || matchInfo?.localDateMatchPlannedStart)
					.format('MMM D - h:mm A')
			};
		}

		if (matchCardType === 'RESULT') {
			matchObject = {
				...matchObject,
				gameOneEndDate: matchInfo.gameOneEndDate,
				gameTwoEndDate: matchInfo.gameTwoEndDate,
				gameThreeEndDate: matchInfo.gameThreeEndDate,
				gameFourEndDate: matchInfo.gameFourEndDate,
				gameFiveEndDate: matchInfo.gameFiveEndDate
			};
		}

		return matchObject;
	});

	return matches;
}
