import { RankingInterfaceV2 } from '@/components/RenderRankings/types';
import { MatchInfo } from '@/modules/ticker/types';
import { Subcategory } from '@/types/blog.types';
import { RenderExternalNewsInterface } from '@/utils/actions/homepage/getExternalNews';
import { RenderNewsInterface } from '@/utils/actions/homepage/getNews';
import { RenderSideNewsInterface } from '@/utils/actions/homepage/getNewsByUuids';

export interface PlacementInterface {
	banner_id: string;
	width: string;
	height: string;
	alt_text: string;
	accompanied_html: string;
	target: string;
	tracking_pixel: string;
	body: string;
	redirect_url: string;
	refresh_url: string;
	rct: string;
	rcb: string;
	refresh_time: string;
	placement_id: string;
	user_frequency_views: string;
	user_frequency_start: string;
	user_frequency_expiry: string;
	viewable_url: string;
	eligible_url: string;
	is_redirectable: boolean;
	image_url: string;
	impressions_remaining: number;
	has_quota: boolean;
	accupixel_url: string;
}

export interface ModalPlacementInterface {
	banner_id: string;
	width: string;
	height: string;
	alt_text: string;
	accompanied_html: string;
	target: string;
	tracking_pixel: string;
	body: string;
	redirect_url: string;
	refresh_url: string;
	rct: string;
	rcb: string;
	refresh_time: string;
	placement_id: string;
	user_frequency_views: string;
	user_frequency_start: string;
	user_frequency_expiry: string;
	viewable_url: string;
	eligible_url: string;
	is_redirectable: boolean;
	image_url: string;
	accupixel_url: string;
}

export interface EditorState {
	structure: ColumnInterface[];
	lastUpdatedStructure?: ColumnInterface[];
	selectedWidgetId?: string;
	editingWidget?: ColumnWidgetType;
	insertingColumnIndex?: 0 | 1 | 2;
	isSaving?: boolean;
	successfullySaved?: boolean;
	error?: string;
	hasError?: boolean;
}

export enum BlockWidgetTypes {
	NEWS = 'NEWS', // NewsInterface has its own newsType property which can be 'format-a' (NewsCard) or 'format-b' (SmallNewsCard)
	VIDEO = 'VIDEO', // This is a NewsCard with optional property 'video' set to true
	IMAGE = 'IMAGE', // This is an ImageCard
	EXTERNAL_NEWS = 'EXTERNAL_NEWS', // ExternalNewsInterface has its own newsType property which can be 'format-a' (NewsCard) or 'format-b' (SmallNewsCard)
	MATCH = 'MATCH', // This is a MatchCardsContainer
	RESULT = 'RESULT', // This is a ResultCard
	CAROUSEL = 'CAROUSEL', // This is a Carousel
	MATCHV3 = 'MATCHV3' // This is a MatchV3Card
}

export interface SidebarWidgetInterface {
	area: 'CONTENT_AREA' | 'SIDEBAR'; // this represents area where this widget can be dropped (SIDEBAR or CONTENT_AREA)
	label: string;
	type: ColumnWidgetTypes; // this represents the type of secondary-sidebar widget (only column widgets can be dropped to columns)
}

export enum ColumnTypes {
	SIDEBAR_1 = 'SIDBAR_1_HOME_PAGE_COLUMN',
	CONTENT_AREA = 'CONTENT_AREA_HOME_PAGE_COLUMN',
	SIDEBAR_2 = 'SIDBAR_2_HOME_PAGE_COLUMN'
}

export enum ColumnWidgetTypes {
	BLOCK = 'BLOCK',
	NEWS = 'NEWS',
	VIDEO = 'VIDEO',
	IMAGE = 'IMAGE',
	EXTERNAL_NEWS = 'EXTERNAL_NEWS',
	COMPANY_WEBSITES = 'COMPANY_WEBSITES',
	FEATURED_PARTNERS = 'FEATURED_PARTNERS',
	HEADLINES = 'HEADLINES',
	RANKINGS = 'RANKINGS',
	SIDE_NEWS = 'SIDE_NEWS',
	SOCIAL_MEDIA = 'SOCIAL_MEDIA',
	SIDE_ADVERTISEMENT = 'SIDE_ADVERTISEMENT',
	MIDDLE_ADVERTISEMENT = 'MIDDLE_ADVERTISEMENT',
	CAROUSEL = 'CAROUSEL'
}

// This interface represents a column
export interface ColumnInterface {
	type: ColumnTypes;
	widgets: ColumnWidgetType[];
}

// Widgets with one of these types can go inside a column
export type ColumnWidgetType =
	| BlockInterface
	| NewsWidgetInterface
	| VideoWidgetInterface
	| ImageWidgetInterface
	| ExternalNewsWidgetInterface
	| CompanyWebsitesInterface
	| FeaturedPartnersInterface
	| HeadlinesInterface
	| RankingsInterface
	| SideNewsInterface
	| SocialMediaInterface
	| SideAdvertisementInterface
	| MiddleAdvertisementInterface
	| CarouselWidgetInterface;

// Represents block widget that can be inside a column (widget with BLOCK type)
export interface BlockInterface {
	homepageId: number;
	column: ColumnTypes.CONTENT_AREA;
	type: ColumnWidgetTypes.BLOCK;
	metadata: BlockMetadataInterace;
	displayOrder: number;
	uuid: string;
	dateCreated?: Date | string;
	dateDisplayFrom?: Date | string;
	dateDisplayTo?: Date | string;
	createdByFullName?: string;
	updateByFullName?: string;
	dateUpdated?: Date | string;
	dateArchived?: Date | string;
}

// Represents news widget that can be inside a column (widget with NEWS type)
export interface NewsWidgetInterface {
	homepageId: number;
	column: ColumnTypes.CONTENT_AREA;
	type: ColumnWidgetTypes.NEWS;
	metadata: NewsInterface;
	displayOrder: number;
	uuid: string;
	dateCreated?: Date | string;
	dateDisplayFrom?: Date | string;
	dateDisplayTo?: Date | string;
	createdByFullName?: string;
	updateByFullName?: string;
	dateUpdated?: Date | string;
	dateArchived?: Date | string;
}

// Represents video widget that can be inside a column (widget with VIDEO type)
export interface VideoWidgetInterface {
	homepageId: number;
	column: ColumnTypes.CONTENT_AREA;
	type: ColumnWidgetTypes.VIDEO;
	metadata: VideoInterface;
	displayOrder: number;
	uuid: string;
	dateCreated?: Date | string;
	dateDisplayFrom?: Date | string;
	dateDisplayTo?: Date | string;
	createdByFullName?: string;
	updateByFullName?: string;
	dateUpdated?: Date | string;
	dateArchived?: Date | string;
}

// Represents image widget that can be inside a column (widget with IMAGE type)
export interface ImageWidgetInterface {
	homepageId: number;
	column: ColumnTypes.CONTENT_AREA;
	type: ColumnWidgetTypes.IMAGE;
	metadata: ImageInterface;
	displayOrder: number;
	uuid: string;
	dateCreated?: Date | string;
	dateDisplayFrom?: Date | string;
	dateDisplayTo?: Date | string;
	createdByFullName?: string;
	updateByFullName?: string;
	dateUpdated?: Date | string;
	dateArchived?: Date | string;
}

// Represents external news widget that can be inside a column (widget with EXTERNAL_NEWS type)
export interface ExternalNewsWidgetInterface {
	homepageId: number;
	column: ColumnTypes.CONTENT_AREA;
	type: ColumnWidgetTypes.EXTERNAL_NEWS;
	metadata: ExternalNewsInterface;
	displayOrder: number;
	uuid: string;
	dateCreated?: Date | string;
	dateDisplayFrom?: Date | string;
	dateDisplayTo?: Date | string;
	createdByFullName?: string;
	updateByFullName?: string;
	dateUpdated?: Date | string;
	dateArchived?: Date | string;
}

// Represents company websites widget that can be inside a column (widget with COMPANY_WEBSITES type)
export interface CompanyWebsitesInterface {
	homepageId: number;
	column: ColumnTypes.SIDEBAR_1 | ColumnTypes.SIDEBAR_2;
	type: ColumnWidgetTypes.COMPANY_WEBSITES;
	metadata: CompanyWebsitesMetadataInterface;
	displayOrder: number;
	uuid: string;
	dateCreated?: Date | string;
	dateDisplayFrom?: Date | string;
	dateDisplayTo?: Date | string;
	createdByFullName?: string;
	updateByFullName?: string;
	dateUpdated?: Date | string;
	dateArchived?: Date | string;
}

// Represents featured partners widget that can be inside a column (widget with FEATURED_PARTNERS type)
export interface FeaturedPartnersInterface {
	homepageId: number;
	column: ColumnTypes.SIDEBAR_1 | ColumnTypes.SIDEBAR_2;
	type: ColumnWidgetTypes.FEATURED_PARTNERS;
	metadata: FeaturedPartnersMetadataInterface;
	displayOrder: number;
	uuid: string;
	dateCreated?: Date | string;
	dateDisplayFrom?: Date | string;
	dateDisplayTo?: Date | string;
	createdByFullName?: string;
	updateByFullName?: string;
	dateUpdated?: Date | string;
	dateArchived?: Date | string;
}

// Represents headlines widget that can be inside a column (widget with HEADLINES type)
export interface HeadlinesInterface {
	homepageId: number;
	column: ColumnTypes.SIDEBAR_1 | ColumnTypes.SIDEBAR_2;
	type: ColumnWidgetTypes.HEADLINES;
	metadata: HeadlinesMetadataInterface;
	displayOrder: number;
	uuid: string;
	dateCreated?: Date | string;
	dateDisplayFrom?: Date | string;
	dateDisplayTo?: Date | string;
	createdByFullName?: string;
	updateByFullName?: string;
	dateUpdated?: Date | string;
	dateArchived?: Date | string;
}

// Represents rankings widget that can be inside a column (widget with RANKINGS type)
export interface RankingsInterface {
	homepageId: number;
	column: ColumnTypes.SIDEBAR_1 | ColumnTypes.SIDEBAR_2;
	type: ColumnWidgetTypes.RANKINGS;
	metadata: RankingsMetadataInterface;
	displayOrder: number;
	uuid: string;
	dateCreated?: Date | string;
	dateDisplayFrom?: Date | string;
	dateDisplayTo?: Date | string;
	createdByFullName?: string;
	updateByFullName?: string;
	dateUpdated?: Date | string;
	dateArchived?: Date | string;
}

// Represents side news widget that can be inside a column (widget with SIDE_NEWS type)
export interface SideNewsInterface {
	homepageId: number;
	column: ColumnTypes.SIDEBAR_1 | ColumnTypes.SIDEBAR_2;
	type: ColumnWidgetTypes.SIDE_NEWS;
	metadata: SideNewsMetadataInterface;
	displayOrder: number;
	uuid: string;
	dateCreated?: Date | string;
	dateDisplayFrom?: Date | string;
	dateDisplayTo?: Date | string;
	createdByFullName?: string;
	updateByFullName?: string;
	dateUpdated?: Date | string;
	dateArchived?: Date | string;
}

// Represents social media widget that can be inside a column (widget with SOCIAL_MEDIA type)
export interface SocialMediaInterface {
	homepageId: number;
	column: ColumnTypes.SIDEBAR_1 | ColumnTypes.SIDEBAR_2;
	type: ColumnWidgetTypes.SOCIAL_MEDIA;
	metadata: SocialMediaMetadataInterface;
	displayOrder: number;
	uuid: string;
	dateCreated?: Date | string;
	dateDisplayFrom?: Date | string;
	dateDisplayTo?: Date | string;
	createdByFullName?: string;
	updateByFullName?: string;
	dateUpdated?: Date | string;
	dateArchived?: Date | string;
}

// Represents side advertisement widget that can be inside a column (widget with SIDE_ADVERTISEMENT type)
export interface SideAdvertisementInterface {
	homepageId: number;
	column: ColumnTypes.SIDEBAR_1 | ColumnTypes.SIDEBAR_2;
	type: ColumnWidgetTypes.SIDE_ADVERTISEMENT;
	metadata: SideAdvertisementMetadataInterface;
	displayOrder: number;
	uuid: string;
	dateCreated?: Date | string;
	dateDisplayFrom?: Date | string;
	dateDisplayTo?: Date | string;
	createdByFullName?: string;
	updateByFullName?: string;
	dateUpdated?: Date | string;
	dateArchived?: Date | string;
}

export interface MiddleAdvertisementInterface {
	uuid: string;
	type: ColumnWidgetTypes.MIDDLE_ADVERTISEMENT;
	metadata: MiddleAdvertisementMetadataInterface;
}

// Represents carousel widget that can be inside a column (widget with CAROUSEL type)
export interface CarouselWidgetInterface {
	homepageId: number;
	column: ColumnTypes.CONTENT_AREA;
	type: ColumnWidgetTypes.CAROUSEL;
	metadata: CarouselInterface;
	displayOrder: number;
	uuid: string;
	dateCreated?: Date | string;
	dateDisplayFrom?: Date | string;
	dateDisplayTo?: Date | string;
	createdByFullName?: string;
	updateByFullName?: string;
	dateUpdated?: Date | string;
	dateArchived?: Date | string;
}

export interface BlockMetadataInterace {
	blockHeader?: BlockHeaderInterface;
	blockComponents: BlockComponentType[];
	blockFooter?: BlockFooterInterface;
}

export interface NewsInterface {
	id: string;
	type: BlockWidgetTypes.NEWS;
	newsType: NewsFormatType;
	slug: string;
	prefetchedData?: RenderNewsInterface;
}

export interface VideoInterface {
	id: string;
	type: BlockWidgetTypes.VIDEO;
	featuredImageURL?: string;
	videoURL: string;
	title?: string;
}

export interface ImageInterface {
	id: string;
	type: BlockWidgetTypes.IMAGE;
	imageURL: string;
	imageAltText?: string;
	externalURL: string;
	description?: string;
}

export interface ExternalNewsInterface {
	id: string;
	type: BlockWidgetTypes.EXTERNAL_NEWS;
	newsType: NewsFormatType;
	url: string;
	title?: string;
	description?: string;
	image?: string;
	author?: string;
	datePublished?: string;
	prefetchedData?: RenderExternalNewsInterface;
}

export interface CompanyWebsitesMetadataInterface {
	title?: string;
	websites: WebsiteInterface[];
}

export interface FeaturedPartnersMetadataInterface {
	title?: string;
	partners: PartnerInterface[];
}

export interface HeadlinesMetadataInterface {
	data: unknown;
	prefetchedData?: HeadlineNewsInterface[];
}

type LeaderboardValue = 1 | 2 | 3 | 4 | 5;
type LeaderboardLabel = 'WD' | 'WS' | 'WX' | 'MX' | 'MD' | 'MS';
type LeaderboardGender = 'F' | 'M';
type LeaderboardSeconds = 5 | 10 | 15 | 30;

export interface ActualLeaderboardTabFullOption {
	value: LeaderboardValue;
	label: LeaderboardLabel;
	gender: LeaderboardGender;
}

export interface LeaderboardOption {
	tabValue: LeaderboardValue;
	tabLabel: LeaderboardLabel;
	tabGender: LeaderboardGender;
	changeTabs: boolean;
	secondsInBetweenChanges: LeaderboardSeconds;
}

export interface RankingsMetadataInterface {
	data: LeaderboardOption;
	prefetchedData?: RankingInterfaceV2[];
}

export interface SideNewsMetadataInterface {
	title: string;
	sideNews: SideNewsRowInterface[];
	prefetchedData?: RenderSideNewsInterface[];
}

export interface SocialMediaMetadataInterface {
	title?: string;
	socialMedias: SocialMediaRowInterface[];
}

export interface SideAdvertisementMetadataInterface {
	zoneId: string;
	width: string;
	height: string;
	place?: string;
	prefetchedData?: PlacementInterface;
}

export interface CarouselInterface {
	id: string;
	type: BlockWidgetTypes.CAROUSEL;
	carouselNews: CarouselNewsInterface[];
	prefetchedData?: RenderSideNewsInterface[];
}

export interface MiddleAdvertisementMetadataInterface {
	zoneId: string;
	width: string;
	height: string;
	place: string;
	prefetchedData?: PlacementInterface;
}

export interface BlockHeaderInterface {
	headerType: BlockHeaderType;
	title: string;
	altTitle?: string;
	imageURL?: string;
	imageAltText?: string;
	link?: string;
	backgroundColor?: string;
}

export type BlockComponentType =
	| NewsInterface
	| VideoInterface
	| ImageInterface
	| ExternalNewsInterface
	| MatchCardsContainerInterface
	| ResultInterface
	| CarouselInterface
	| MatchV3Interface;

export interface BlockFooterInterface {
	buttons: ButtonInterface[];
}

export type NewsFormatType = 'format-a' | 'format-b';

export interface WebsiteInterface {
	id: string;
	name: string;
	link: string;
	image: string;
}

export interface PartnerInterface {
	id: string;
	name: string;
	link: string;
	image: string;
}

export type SocialMediaType = 'Facebook' | 'Instagram' | 'Snapchat' | 'TikTok' | 'X/Twitter' | 'YouTube';

export interface SideNewsRowInterface {
	id: string;
	uuid: string;
	title: string;
}

export interface SocialMediaRowInterface {
	id: string;
	name: SocialMediaType;
	link: string;
}

export interface CarouselNewsInterface {
	id: string;
	uuid: string;
	title: string;
}

// BlochHeader with headerType set to 'type-a' has an image on the start (left) and a title just next to it
// BlockHeader with headerType set to 'type-b' has an image at the end (all the way on the right) and a title on the start (left)
// BlochHeader with headerType set to 'type-c' doesn't have an image, but has in the middle a text with a custom background color which can be a link
export type BlockHeaderType = 'type-a' | 'type-b' | 'type-c';

export interface MatchCardsContainerInterface {
	type: BlockWidgetTypes.MATCH;
	id: string;
	matches: MatchInterface[];
	title: string;
	invisible?: boolean;
	prefetchedData?: MatchInfo[];
}

export interface MatchInterface {
	id: string;
	tournamentId: string;
	eventId: string;
	matchId: string;
	matchTitle: string;
}

export interface ResultInterface {
	type: BlockWidgetTypes.RESULT;
	id: string;
	resultInfo: MatchInterface;
	invisible?: boolean;
	prefetchedData?: MatchInfo[];
}

export interface MatchV3Interface {
	type: BlockWidgetTypes.MATCHV3;
	id: string;
	match: MatchInterface;
	invisible?: boolean;
	prefetchedData?: MatchInfo[];
}

// For a Button 'text' property represent what text is inside of a Button and a link defines which URL opens when you click the Button
export interface ButtonInterface {
	id: string;
	text: string;
	link: string;
}

export interface NewsDetailsInterface {
	uuid: string;
	title: string;
	imageUrl: string;
	imageAltText: string;
	authorFullName: string;
	dateCreated: Date | string;
	facebookLink: string;
	twitterLink: string;
	smsLink: string;
	videoUrl: string;
	content: string;
	newsTagsUuids: string[];
	dateArchived: Date | string;
	dateStartShowOnSite: Date | string;
	dateEndShowOnSite: Date | string;
	publishDateDisplayed: Date | string;
	smallNewsCardImgUrl: string;
	headlineForHomepage: string;
	dateUpdated: Date | string;
	createdByFullName: string;
	updatedByFullName: string;
	archivedByFullName: string;
	description: string;
	detailsUrl: string;
	slug: string;
	status: string;
	newsAuthorUuid: string;
	externalUrl: string;
	dateStartShowOnFeatured: Date | string;
	dateEndShowOnFeatured: Date | string;
	tabTitle: string;
	metaTitle: string;
	metaDescription: string;
	imageCaption: string;
	imageSource: string;
	isActive: boolean;
	categoryUuid: string;
	subcategoryUuids: string[];
	newsCategory: NewsCategoryInterface;
}

export interface NewsCategoryInterface {
	uuid: string;
	title: string;
	slug: string;
	description: string;
	isActive: boolean;
	dateCreated: string;
	dateUpdated: string;
	userIdCreated: string;
	userIdUpdated: string;
	articleCount: string;
	subcategories: Subcategory[];
}

export interface NewsCategoryShorInterface {
	uuid: string;
	title: string;
	slug: string;
}

export interface HeadlineNewsInterface {
	id: string;
	slug: string;
	title: string;
	headlineForHomepage: string;
	image: string;
	visibleFrom?: Date;
	visibleTo?: Date;
	status: string;
	categorySlug?: string;
}

export interface HeadlineState {
	structure: HeadlineNewsInterface[];
	maxPinned: number;
}

export interface IpApi {
	query: string;
	status: string;
	continent: string;
	continentCode: string;
	country: string;
	countryCode: string;
	region: string;
	regionName: string;
	city: string;
	district: string;
	zip: string;
	lat: number;
	lon: number;
	timezone: string;
	offset: number;
	currency: string;
	isp: string;
	org: string;
	as: string;
	asname: string;
	mobile: false;
	proxy: boolean;
	hosting: boolean;
}

export interface MasterDBV2Response<T> {
	totalRecords: number;
	currentPage: number;
	pageSize: number;
	nextPage: string;
	prevPage: string;
	results: T;
}

export interface NewsResponse {
	newsArticles: NewsDetailsInterface[];
}

export interface NewsCategoriesResponse {
	categories: NewsCategoryInterface[];
}

export interface LocationData {
	country?: { title?: string; abbreviation?: string; id?: number };
	state?: { title?: string; abbreviation?: string; id?: number };
	city?: string;
	address1?: string;
}
