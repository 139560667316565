import Link from 'next/link';
import React from 'react';

import type { ImageInterface } from '@/utils/types';

import { ImageCard } from '../ImageCard';

const RenderImage = ({ externalURL, imageURL, imageAltText, description }: ImageInterface) => {
	if (externalURL)
		return (
			<Link className="hover:opacity-80" href={externalURL} rel="noopener noreferrer" target="_blank">
				<ImageCard imageURL={imageURL} imageAltText={imageAltText} description={description} />
			</Link>
		);
	return <ImageCard imageURL={imageURL} imageAltText={imageAltText} description={description} />;
};

export default RenderImage;
