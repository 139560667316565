import { StructureInterface } from '@/utils/actions/homepage/getHomepageStructure';
import { ColumnWidgetTypes } from '@/utils/types';

const insertAdsPlacements = (structure: StructureInterface, adsPlacesHashmap: Map<string, number>): void => {
	structure.sidebar1.widgets.forEach((widget) => {
		if (widget.type === ColumnWidgetTypes.SIDE_ADVERTISEMENT) {
			const prevValue = adsPlacesHashmap.get(widget.metadata.zoneId);
			const newValue = prevValue !== undefined ? prevValue + 1 : 0;
			adsPlacesHashmap.set(widget.metadata.zoneId, newValue);
			widget.metadata.place = newValue.toString();
		}
	});
	structure.sidebar2.widgets.forEach((widget) => {
		if (widget.type === ColumnWidgetTypes.SIDE_ADVERTISEMENT) {
			const prevValue = adsPlacesHashmap.get(widget.metadata.zoneId);
			const newValue = prevValue !== undefined ? prevValue + 1 : 0;
			adsPlacesHashmap.set(widget.metadata.zoneId, newValue);
			widget.metadata.place = newValue.toString();
		}
	});
};

export default insertAdsPlacements;
